<template>
  <div
    v-if="view"
    class="fixed top-0 left-0 flex flex-col gap-32 px-24 py-16 w-screen h-screen bg-dark z-[10000]"
  >
    <div class="flex justify-between items-center w-full">
      <Link url="memes" @click="close()">
        <div class="flex justify-center items-center rounded-sm px-8 py-2">
          <Gigalol class="h-20 -ml-8" />
        </div>
      </Link>

      <IconClose class="h-20 w-20" @click="close()" />
    </div>

    <Button
      v-if="auth.authenticated"
      @click="openMemeModal()"
      data-test="meme-button"
    >
      {{ $t("header.meme") }}
    </Button>

    <div v-else class="flex justify-between gap-8">
      <Link url="signup" :underline="false" class="grow">
        <Button class="w-full">
          {{ $t("header.signup") }}
        </Button>
      </Link>

      <Link url="login" :underline="false" class="grow">
        <Button variant="secondary" class="w-full">
          {{ $t("header.login") }}
        </Button>
      </Link>
    </div>

    <Link url="https://www.humandolls.com" class="relative">
      <img
        :src="Humandolls"
        class="w-full min-h-[200px] max-h-[80rem] rounded-xl aspect-video object-cover"
      />

      <div
        class="absolute bottom-0 left-0 right-0 h-256 max-h-full w-full rounded-b-xl"
        style="
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.35) 0%,
            rgba(0, 212, 255, 0) 100%
          );
        "
      ></div>

      <h2 class="absolute bottom-24 left-24 mb-0 leading-s font-black">
        SAVE UP TO €2600 <br />
        ON A HUMAN DOLL <br />
        <br />
        26 OCT — 02 NOV
      </h2>

      <button
        type="button"
        class="absolute bottom-24 right-24 flex items-center gap-6 font-heading lowercase h-fit p-4 rounded-full cursor-pointer bg-white text-dark"
      >
        <IconChevronUp class="h-32 w-32 rotate-90" />
      </button>
    </Link>

    <div class="flex flex-col gap-24 text-20 font-bold">
      <div v-for="(route, i) in headerRoutes" :key="i">
        <MobileMenuItem
          :url="route.url || ''"
          :params="route.params"
          :label="route.label"
          :icon="route.icon"
          :children="route.children || []"
          @click="handleClick(route.url)"
        />
      </div>

      <div
        v-if="auth.authenticated"
        class="w-fit"
        @click="auth.logout()"
        data-test="logout-button"
      >
        {{ $t("header.menu.logout") }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useNewMemesStore } from "~/components/modal/meme/+new-memes.store";
import { useAuth } from "~/plugins/auth";
import { headerRoutes } from "../header-routes";

import Gigalol from "~/assets/icons/gigalol.vue";
import IconChevronUp from "~/assets/icons/icon-chevron-up.vue";
import IconClose from "~/assets/icons/icon-close.vue";
import Humandolls from "~/assets/img/humandolls.webp";
import Link from "~/components/shared/link.vue";
import Button from "~/components/ui/button.vue";
import MobileMenuItem from "./mobile-menu-item.vue";

defineProps({
  view: {
    type: Boolean,
    required: true,
  },
});

const auth = useAuth();

const emit = defineEmits(["update:view"]);
function close() {
  emit("update:view", false);
}

function openMemeModal() {
  close();
  useNewMemesStore().showModal = true;
}

const route = useRoute();
const routeName = computed(() => route.name);
const router = useRouter();
function handleClick(url?: string) {
  if (url === "memes" && routeName.value === "memes") {
    router.go(0);
  }
  close();
}
</script>
